import React, { useEffect } from 'react'
import { createUseStyles } from '../../../helpers/createStyles'
import { theme } from '../../../styles/theme'
import Section from '../../Section'
import ProductTile from './ProductTile'
import { trackProductImpression, ITEM_LIST_SOURCES } from '../../../lib/gtag'
import useScrollTrigger from '../../../hooks/useScrollTrigger'
import useSnapshot from '../../../store/useSnapshot'
import { getCurrencyCode } from '../../../store/pricesSlice'

export default function ProductTiles ({ data }) {
  const styles = useStyles()
  const snap = useSnapshot()
  const currencyCode = getCurrencyCode(snap)
  const { products, layout } = data

  const sectionRef = useScrollTrigger(
    () => ({
      trigger: sectionRef.current,
      start: () => `${window.innerHeight / 4}px bottom`,
      scrub: false
    }),
    (tl, ref) => {
      tl.from(ref.current.querySelectorAll('article'), { y: 40, opacity: 0, stagger: 0.2, ease: 'expo.out', duration: 1.5 })
    }
  )

  useEffect(() => {
    if (currencyCode && products.length > 0) {
      const datalayerObjects = products?.map(({ productVariant: { product, variantId } }) => {
        return {
          id: variantId,
          name: product.title,
          category: product.productCategory?.name,
          variant: product.colors.find((color) => color.bigCommerceId === variantId)?.label
        }
      })
      trackProductImpression(datalayerObjects, ITEM_LIST_SOURCES.featuredCollection, currencyCode)
    }
  }, [products, currencyCode])

  return (
    <Section className={styles.container} grid style={{ rowGap: theme.spacing(4) }} ref={sectionRef}>
      {products?.map(({ image, productVariant: { product, variantId } }, i) => (
        <ProductTile
          tileIndex={i}
          tileSource={ITEM_LIST_SOURCES.featuredCollection}
          key={product.title + variantId}
          layout={layout}
          product={product}
          colorVariantId={variantId}
          image={image}
          mobileAspect={i % 3 === 0 ? 327 / 350 : 156 / 214}
          showPreviewImage={false}
        />
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  container: {
    '& > article': {
      zIndex: 4
    },
    '& > *:nth-child(1)': {
      gridColumn: 'span 8',
      [theme.breakpoints.up('md')]: {
        gridColumn: 'span 4'
      }
    },
    '& > *:nth-child(2)': {
      gridColumn: 'span 4',
      [theme.breakpoints.up('md')]: {
        gridColumn: 'span 5'
      }
    },
    '& > *:nth-child(3)': {
      gridColumn: 'span 4',
      [theme.breakpoints.up('md')]: {
        gridColumn: 'span 3'
      }
    },
    '.dialog-grid &': {
      '& > *:nth-child(1)': {
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 3'
        }
      }
    }
  }
})
